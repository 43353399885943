/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import RenderForm from 'gatsby-plugin-cloudofertas'
// import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'
import { Collapse, CardBody, Card } from 'reactstrap'
import './styles/atendimento.scss'
// import axios from 'axios'
import fb from '../images/atendimento/facebook.svg'
import insta from '../images/atendimento/instagram.svg'
import iconemais from '../images/atendimento/botaomais.svg'
import iconemenos from '../images/atendimento/botaomenos.svg'
// import InputMask from 'react-input-mask'
import { Helmet } from 'react-helmet'

// const sendEvent = (action, label = 'none') => {
//   // eslint-disable-next-line no-undef
//   if (typeof window !== 'undefined') {
//     if (!window.dataLayer) {
//       window.dataLayer = []
//     }
//     window.dataLayer.push({
//       event: 'contato',
//       eventAction: action,
//       eventLabel: label
//     })
//   }
// }

const SectionDuvidas = () => {
  const [isCollapse, setIsCollapse] = useState(false)
  const [isCollapse2, setIsCollapse2] = useState(false)
  const [isCollapse3, setIsCollapse3] = useState(false)
  const [isCollapse4, setIsCollapse4] = useState(false)
  const [isCollapse5, setIsCollapse5] = useState(false)
  const [isCollapse6, setIsCollapse6] = useState(false)
  const [isCollapse7, setIsCollapse7] = useState(false)
  const [isCollapse8, setIsCollapse8] = useState(false)
  return (
    <>
      <div id={`${isCollapse ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(!isCollapse) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false) || setIsCollapse7(false) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Como Comprar<img style={{ width: 47, height: 47 }} src={isCollapse ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse}>
        <Card className="border-0">
          <CardBody id="collapseText">
            Tanto o consumidor final como o varejista podem comprar no Spani Atacadista indo diretamente em uma das nossas lojas. Não é necessário carteirinha.
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse2 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(!isCollapse2) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false) || setIsCollapse7(false) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Como Comprar com Cheque<img style={{ width: 47, height: 47 }} src={isCollapse2 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse2}>
        <Card className="border-0">
          <CardBody id="collapseText">
            Tudo é muito simples, fácil e rápido: cadastre-se em uma loja Spani, apresente a documentação necessária e faça suas compras na hora, sem burocracia. *A liberação do cadastro dependerá das consultas realizadas no balcão.
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse3 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse3(!isCollapse3) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false) || setIsCollapse7(false) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Como faço para adquirir o cartão Spani?<img style={{ width: 47, height: 47 }} src={isCollapse3 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse3}>
        <Card className="border-0">
          <CardBody id="collapseText">
            Para fazer o seu cartão Spani, por gentileza vá até uma de nossas lojas portando o RG e CPF. O cartão está sujeito à análise de crédito e em alguns casos será solicitado comprovante de renda e/ou comprovante de residência.
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse4 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(!isCollapse4) || setIsCollapse5(false) || setIsCollapse6(false) || setIsCollapse7(false) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Como faço para pagar minha fatura?<img style={{ width: 47, height: 47 }} src={isCollapse4 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse4}>
        <Card className="border-0">
          <CardBody id="collapseText">
            Para mais informações sobre o seu Cartão Spani entre em contato com a operadora, pelo 0800.702.5004, no site: http://www.dmcard.com.br/portal/paginas/areacliente , pelo whatsapp (12) 2136-0100 e também no balcão de cadastros de nossas lojas!
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse5 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(!isCollapse5) || setIsCollapse6(false) || setIsCollapse7(false) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Para onde devo mandar meu currículo?<img style={{ width: 47, height: 47 }} src={isCollapse5 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse5}>
        <Card className="border-0">
          <CardBody id="collapseText">
            Envie seu currículo para curriculo@grupozaragoza.com.br No título do e-mail informe: Cargo desejado | Loja de interesse | Nome completo. Você também pode deixar pessoalmente em uma loja mais próxima de você. Agradecemos pela iniciativa de fazer parte da nossa equipe! Boa sorte!
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse6 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(!isCollapse6) || setIsCollapse7(false) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Posso pagar com cheque?<img style={{ width: 47, height: 47 }} src={isCollapse6 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse6}>
        <Card className="border-0">
          <CardBody id="collapseText">
            O Spani aceita cartões de débito ou crédito, cartões alimentação ou dinheiro. Os pagamentos realizados com cheques são mediante cadastro e aprovação de nosso departamento financeiro. Para saber mais consulte o balcão de atendimento da loja mais próxima de você.
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse7 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false) || setIsCollapse7(!isCollapse7) || setIsCollapse8(false)} className="font-openSans-extrabold p-3">Quais são as formas de pagamento?<img style={{ width: 47, height: 47 }} src={isCollapse7 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse7}>
        <Card className="border-0">
          <CardBody id="collapseText">
            Aceitamos os seguintes cartões de débito e crédito: AMEX, CABAL CRÉDITO, CABAL DEBITO, DINERS, DMCARD, ELO CREDITO, ELO DEBITO, HIPERCARD, MASTERCARD CREDITO, MASTER DEBITO, SOROCRED, VISA CREDITO e VISA ELECTRON Aceitamos o Auxílio Emergencial da Caixa
            Já os cartões alimentação, aceitamos: ALELO, BEN VISA VALE, GREENCARD, PLANTÃO CARD, SODEXO, TICKET, USECRED, VALECARD e VEGAS CARD
          </CardBody>
        </Card>
      </Collapse>
      <div id={`${isCollapse8 ? 'collapseDuvidasActive' : 'collapseDuvidas'}`} onClick={() => setIsCollapse(false) || setIsCollapse2(false) || setIsCollapse3(false) || setIsCollapse4(false) || setIsCollapse5(false) || setIsCollapse6(false) || setIsCollapse7(false) || setIsCollapse8(!isCollapse8)} className="font-openSans-extrabold p-3">Qual horário de funcionamento das lojas?<img style={{ width: 47, height: 47 }} src={isCollapse8 ? iconemenos : iconemais} alt="icone de + e -" /></div>
      <Collapse isOpen={isCollapse8}>
        <Card className="border-0">
          <CardBody id="collapseText">
            LOJAS DE RESENDE/ VOLTA REDONDA/ LORENA: De segunda à sábado das 07h às 22h. Domingos e feriados das 08h às 20h. LOJAS SJC - AQUARIUS/ CARAGUATATUBA: De segunda à sábado das 07h às 22h. Domingos e feriados das 07h às 20h. LOJAS PINDAMONHANGABA/ SJC – VISTA VERDE/ GUARATINGUETÁ/ MOGI DAS CRUZES/ ATIBAIA / BRAGANÇA PAULISTA: De segunda à sábado das 07h às 22h. Domingos e feriados das 08h às 18h30.
          </CardBody>
        </Card>
      </Collapse>
    </>
  )
}

const Atendimento = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/atendimento/', name: 'Atendimento' }
    ]
  }

  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)

  // function validaEmail () {
  //   return !!formState.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
  // }

  // function validaEmail () {
  //   return !!formState.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
  // }

  // function resetForm () {
  //   formState.assunto = ''
  //   formState.nome = ''
  //   formState.email = ''
  //   formState.telefone = ''
  //   formState.lojaAtendimento = 'Selecione uma loja'
  //   formState.cidade = ''
  //   formState.mensagem = ''
  // }

  // function handleFormValidation () {
  //   setSuccessMessage(false)
  //   if (formState && Object.keys(formState).length < 7) {
  //     setErrorMessage('Preencha todos os campos')
  //   } else if (formState.nome.match(/[0-9]/gi)) {
  //     setErrorMessage('Nomes não podem conter números!')
  //   } else if (formState.nome.length < 1) {
  //     setErrorMessage('O campo nome é obrigatório!')
  //   } else if (!validaEmail()) {
  //     setErrorMessage('Digite um Email Válido!')
  //   } else if (formState.telefone.length < 9) {
  //     setErrorMessage('Telefone Inválido!')
  //   } else if (formState.mensagem.length === 0) {
  //     setErrorMessage('O campo de mensagem é obrigatório!')
  //   } else if (formState.mensagem.length < 5) {
  //     setErrorMessage('Sua mensagem deve conter no mínimo 5 caracteres.')
  //   } else {
  //     return true
  //   }
  //   return false
  // }

  // async function enviarMensagem (event) {
  //   event.preventDefault()
  //   if (handleFormValidation()) {
  //     setClickEnvio(true)
  //     const payload = {
  //       assunto: formState.assunto,
  //       nome: formState.nome,
  //       email: formState.email,
  //       telefone: formState.telefone,
  //       cidade: formState.cidade,
  //       loja: formState.lojaAtendimento,
  //       mensagem: formState.mensagem,
  //       flgNovidades: formState.novidades,
  //       pagina: 'atendimento'
  //     }
  //     try {
  //       await axios.post('https://0iynl7qv67.execute-api.us-east-1.amazonaws.com/prd/email', payload)
  //       setErrorMessage(false)
  //       setSuccessMessage(<>Contato realizado com sucesso!</>)
  //       resetForm()
  //       sendEvent('form', formState.email)
  //       setClickEnvio(false)
  //     } catch (error) {
  //       setSuccessMessage(false)
  //       setErrorMessage(<>Não foi possível concluir o contato. <br/> Por favor, verifique os dados e tente novamente! </>)
  //       setClickEnvio(false)
  //     }
  //   }
  // }

  // const setInput = (e) => {
  //   setFormState({ ...formState, [e.target.name]: e.target.value })
  // }

  // const [telMask, setTelMask] = useState([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])

  const arrayQuestions = [
    {
      Question: 'Como Comprar',
      Answer: 'Tanto o consumidor final como o varejista podem comprar no Spani Atacadista indo diretamente em uma das nossas lojas. Não é necessário carteirinha.'
    },
    {
      Question: 'Como comprar com cheque',
      Answer: 'Tudo é muito simples, fácil e rápido: cadastre-se em uma loja Spani, apresente a documentação necessária e faça suas compras na hora, sem burocracia. *A liberação do cadastro dependerá das consultas realizadas no balcão.'
    },
    {
      Question: 'Como faço para adquirir o cartão Spani?',
      Answer: 'Para fazer o seu cartão Spani, por gentileza vá até uma de nossas lojas portando o RG e CPF. O cartão está sujeito à análise de crédito e em alguns casos será solicitado comprovante de renda e/ou comprovante de residência.'
    },
    {
      Question: 'Como faço para pagar minha fatura?',
      Answer: 'Para mais informações sobre o seu Cartão Spani entre em contato com a operadora, pelo 0800.702.5004, no site: http://www.dmcard.com.br/portal/paginas/areacliente , pelo whatsapp (12) 2136-0100 e também no balcão de cadastros de nossas lojas!'
    },
    {
      Question: 'Para onde devo mandar meu currículo?',
      Answer: 'Envie seu currículo para curriculo@grupozaragoza.com.br No título do e-mail informe: Cargo desejado | Loja de interesse | Nome completo. Você também pode deixar pessoalmente em uma loja mais próxima de você. Agradecemos pela iniciativa de fazer parte da nossa equipe! Boa sorte!'
    },
    {
      Question: 'Posso pagar com cheque?',
      Answer: 'O Spani aceita cartões de débito ou crédito, cartões alimentação ou dinheiro. Os pagamentos realizados com cheques são mediante cadastro e aprovação de nosso departamento financeiro. Para saber mais consulte o balcão de atendimento da loja mais próxima de você.'
    },
    {
      Question: 'Quais são as formas de pagamento?',
      Answer: 'Aceitamos os seguintes cartões de débito e crédito: AMEX, CABAL CRÉDITO, CABAL DEBITO, DINERS, DMCARD, ELO CREDITO, ELO DEBITO, HIPERCARD, MASTERCARD CREDITO, MASTER DEBITO, SOROCRED, VISA CREDITO e VISA ELECTRON Aceitamos o Auxílio Emergencial da Caixa. Já os cartões alimentação, aceitamos: ALELO, BEN VISA VALE, GREENCARD, PLANTÃO CARD, SODEXO, TICKET, USECRED, VALECARD E VEGAS CARD'
    },
    {
      Question: 'Qual horário de funcionamento das lojas?',
      Answer: 'LOJAS DE RESENDE/ VOLTA REDONDA/ LORENA: De segunda à sábado das 07h às 22h. Domingos e feriados das 08h às 20h. LOJAS SJC - AQUARIUS/ CARAGUATATUBA: De segunda à sábado das 07h às 22h. Domingos e feriados das 07h às 20h. LOJAS PINDAMONHANGABA/ SJC – VISTA VERDE/ GUARATINGUETÁ/ MOGI DAS CRUZES/ ATIBAIA / BRAGANÇA PAULISTA: De segunda à sábado das 07h às 22h. Domingos e feriados das 08h às 18h30.'
    }
  ]

  const FAQ = {
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    mainEntity:
    arrayQuestions.map(data => {
      return {
        '@type': 'Question',
        name: data.Question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: data.Answer
        }
      }
    })

  }
  console.log('lojasssss', data.lojas.edges.map(node => (node.node.nome)))

  const fieldLojaContentful = (field, register, errors) => {
    return (
      <>
        <label htmlFor={field.name}>{field.label}</label>
        <select
          id={field.name}
          name={field.name}
          className={`${errors[field.name] ? 'error' : ''}`}
          {...register(field.name, { required: field.required })}
          defaultValue={''}
        >
          <option value='' disabled> Selecione uma loja</option>
          {data.lojas.edges.map((node, index) => {
            return (
              <option key={index}>{((node.node.nome))}</option>
            )
          })}
        </select>
      </>
    )
  }
  const customFields = {
    lojas: fieldLojaContentful
  }

  return (
    <Layout breadCrumb={breadC} >
      <Helmet>
        <script type="application/ld+json">{`${JSON.stringify(FAQ)}`}</script>
      </Helmet>
      <Pagina pagina={data.pagina}/>
      <section className="container mt-4">
        <div className="row">
          <div className="col-12">
            <h1>Atendimento</h1>
            <h2 className="text-black"> Dúvidas Frequentes </h2>
          </div>
        </div>
        <SectionDuvidas />
      </section>
      <div className="bg-gray2 mt-5">
        <div className="container pt-4 pb-4">
          <div className="row">
            <div className="col-12">
              <h2 className="text-black">Para mais dúvidas, perguntas, sugestões e outros assuntos,<br/> preencha os campos abaixo:</h2>
            </div>
          </div>
          <div className="w-100 card pt-3 pb-3 form-spani d-flex align-items-center">
            {
              <RenderForm
                disabledButton={disableBtn}
                setSuccessSubmit={setSubmitSuccess}
                customFields={customFields}
                formData={data.cloudOfertasForm}
                containerStyle='container'
                rowStyle='row'
                inputStyle='custom-form d-flex flex-column mb-2'
                btnContainerStyle='custom-form col-12'
                btnStyle='btn btn-yellow d-flex w-100 text-uppercase justify-content-center font-openSans-extrabold'
                btnName='CADASTRAR'
              />
            }
            {submitSuccess &&
            <div className='container'>
              <div className='row'>
                <div className="col-md-12">
                  <div className="d-flex align-items-center justify-content-center bg-success rounded p-2 my-3">
                    <p
                      className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-items-center"
                      style={{ width: '45px', height: '45px' }} >✔</p>
                    <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                      Contato realizado com sucesso!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            }
            <div className="col-12 pt-4">
              <p className="font-size-08">(*) Campos obrigatórios</p>
            </div>
          </div>
          {/* <form className="w-100 card pt-3 pb-3 form-spani">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <label htmlFor="assunto" className="mb-0 mt-0">Assunto*</label>
                  <input type="text" id="assunto" className="w-100" name="assunto" onChange={(e) => setInput(e)} value={formState.assunto}/>
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="nome" className="mb-0 mt-lg-0 mt-2">Nome Completo*</label>
                  <input type="text" id="nome" className="w-100" name="nome" onChange={(e) => setInput(e)} value={formState.nome}/>
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="email" className="mb-0 mt-2">E-mail*</label>
                  <input type="text" id="email" className="w-100" name="email" onChange={(e) => setInput(e)} value={formState.email}/>
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="telefone" className="mb-0 mt-2">Telefone / Celular*</label>
                  <InputMask mask={telMask} maskPlaceholder={null} type="text" id="telefone" className="w-100" value={formState.telefone} name="telefone" onChange={(e) => {
                    setInput(e)
                    if (e.target.value.match(/\d{2}\s\d{4}-\d{5}/)) {
                      setTelMask([/\d/, /\d/, ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/])
                    } else {
                      setTelMask([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])
                    }
                  }} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="cidade" className="mb-0 mt-2">Cidade</label>
                  <input type="text" id="cidade" className="w-100" name="cidade" onChange={(e) => setInput(e)} value={formState.cidade}/>
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="lojaAtendimento" className="mb-0 mt-2">Selecione uma loja*</label>
                  <select type="text" id="lojaAtendimento" name="lojaAtendimento" onChange={(e) => setInput(e)} className="w-100" value={formState.lojaAtendimento}>
                    <option defaultValue>Selecione uma loja</option>
                    {
                      data.lojas.edges.map(loja => {
                        return (
                          <option value={loja.node.nome} key={loja.node.id}>
                            {loja.node.nome}
                          </option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="col-lg-12 mb-2">
                  <label htmlFor="mensagem" className="mb-0 mt-2">Mensagem*</label>
                  <textarea type="text" id="mensagem" className="w-100" name="mensagem" onChange={(e) => setInput(e)} value={formState.mensagem} />
                </div>
                <div className="col-lg-12 mb-2 d-flex align-items-center">
                  <input type="checkbox" className='w-auto' id="novidades" name="novidades" onChange={(e) => setInput(e)} />
                  <label htmlFor="novidades" className="mb-0 ml-2">Quero receber novidades do Spani Atacadista</label>
                </div>
                <div className="col-12 mt-2">
                  <button className="btn btn-yellow d-flex w-100 text-uppercase justify-content-center font-openSans-extrabold" disabled={clickEnvio === true} onClick={(e) => (enviarMensagem(e))}>cadastrar</button>
                </div>
                {
                  !!errorMessage && (
                    <div className="col-lg-12">
                      <div className="d-flex bg-danger align-items-center justify-content-center py-2 px-4 my-1 rounded">
                        <p className="shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                          style={{ width: '25px', height: '25px' }} >!</p>
                        <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
                {
                  !!successMessage && (
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-center bg-success rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-items-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                          {successMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-12 pt-4">
              <p className="font-size-08">(*) Campos obrigatórios</p>
            </div>
          </form> */}
        </div>
      </div>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-12">
            <h2 className="text-black">Redes Sociais</h2>
          </div>
          <div className="col-12">
            <p>Conheça nossos canais nas redes sociais e fale com a gente por lá também:</p>
          </div>
          <div className="col-auto text-center mt-lg-4">
            <a rel="noopener noreferrer " href="https://www.facebook.com/SpaniAtacadistaOficial/" target="_blank">
              <img src={fb} alt="teste" width='50' height='50' />
              <p>Facebook</p>
            </a>
          </div>
          <div className="col-auto text-center mt-lg-4">
            <a rel="noopener noreferrer " href="https://www.instagram.com/spaniatacadistaoficial/" target="_blank">
              <img src={insta} alt="teste" width='50' height='50' />
              <p>Instagram</p>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Atendimento

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/atendimento/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
  lojas: allContentfulLojas(sort: {order: ASC, fields: nome}) {
    edges {
      node {
        nome
        id
      }
    }
  }
  bgAplicativos: file(relativePath: {eq: "apps/Group.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 582, height: 467, layout: CONSTRAINED)
    }
  }
  cloudOfertasForm(name: {eq: "ATENDIMENTO"}) {
    id
    formTypeField {
      name
      type
      required
      style
      length
      label
      formLov {
        formLovData {
          id
          value
          createdDate
        }
      }
    }
  }
}
`
